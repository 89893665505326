export const onPhoneChange = (event, handleChange, setFieldValue) => {
  handleChange(event);
  const pattern = /^\+420|^00420|^0420/g;
  const value =
    event.target &&
    event.target.value &&
    String(event.target.value).replace(/ /g, '');
  if (setFieldValue && value) {
    setFieldValue('phone', value.replace(pattern, ''), true);
  }
};
